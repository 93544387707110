<template>
  <div class="notification">
    <div class="modal__body notification__body">
      <div class="notification__icon-wrapper" v-if="icon">
        <span :class="icon" />
      </div>
      <div class="notification__text">
        {{ title }}
      </div>
    </div>
    <div class="modal__footer">
      <button class="button button_secondary button_md" @click="clickHandler">
        {{ button }}
      </button>
    </div>
  </div>
</template>

<script>
const icons = {
  check: "icon icon__check icon__check_dark icon__check_md",
};

export default {
  props: {
    options: { type: Object, required: true },
  },

  computed: {
    title() {
      return this.options.title;
    },

    icon() {
      if (!this.options.icon) return null;
      return icons[this.options.icon];
    },

    button() {
      return this.options.button || "close";
    },
  },

  methods: {
    clickHandler() {
      if (typeof this.options.cb === "function") {
        this.options.cb();
      }

      return this.$emit("close");
    },
  },
};
</script>
